<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">Норма Лаборатория</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success"> {{$t('message.save_and_close')}} </el-button>
              <el-button> {{$t('message.close')}} </el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="molad-content">
      <el-form
        ref="form"
        :model="form"
        size="small"
        class="inputBgNone bg_oq p20"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Наименование Услуги">
              <el-select
                filterable
                clearable
                :placeholder="$t('message.corps')"
                size="mini"
                v-model="filterForm.corps_id"
              >
                <el-option
                  v-for="item in service"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="8">
            <el-form-item label="Возраст">
              <el-select
                class="w-100"
                v-model="form.regionq"
                placeholder="Возраст"
              >
                <el-option label="Все" value="shanghai"></el-option>
                <el-option label="12-25" value="beijing"></el-option>
                <el-option label="25-35" value="beijing"></el-option>
                <el-option label="2-10" value="beijing4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="8">
            <el-form-item label="Типы людей">
              <el-select
                class="w-100"
                v-model="form.region"
                placeholder="Типы людей"
              >
                <el-option label="Все" value="shanghai"></el-option>
                <el-option label="Мужчина" value="shanghai"></el-option>
                <el-option label="Женщина" value="beijing"></el-option>
                <el-option label="Дети" value="beijing2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>

        <el-divider
          content-position="left"
          class="mt-2 mb-2"
          @click="outerVisible = true"
        >
          Добавить Норма
        </el-divider>

        <el-row :gutter="20" class="mt-4 mb-3">
          <el-col :span="24" class="">
            <el-button
              class="pr-5 pl-5"
              type="warning"
              round
              @click="outerVisible = true"
            >
              Похожий Норма
            </el-button>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-for="(item, index) in items" :key="item.id">
          <el-col :span="15">
            <el-form-item>
              <el-input
                v-model="form.name2"
                placeholder="Наименование"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="4">
            <el-form-item>
              <el-input
                v-model="form.name3"
                placeholder="Норма От  "
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="4">
            <el-form-item>
              <el-input v-model="form.name3" placeholder="Норма До "></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :span="1">
            <el-button
              v-on:click="deleteGroup(index)"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </el-col>
        </el-row>
        <!-- end el-row -->

        <el-row :gutter="20">
          <el-col :span="24" class="text-center">
            <el-button
              class="pr-5 pl-5"
              type="warning"
              round
              v-on:click="addGroup"
            >
              Добавить
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-dialog
      title=" Похожий Норма "
      :append-to-body="true"
      :visible.sync="outerVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="form"
        size="small"
        class="inputBgNone bg_oq p20"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Наименование Услуги">
              <el-select
                class="w-100"
                v-model="form.region1"
                placeholder="Наименование"
              >
                <el-option label="Zone one" value="shanghai"></el-option>
                <el-option label="Zone two" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="success"> {{$t('message.save_and_close')}} </el-button>
        <el-button @click="outerVisible = false"> {{$t('message.close')}} </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      outerVisible: false,
      form: {},
      items: [
        {
          name3: "",
          name2: "",
        },
      ],
    };
  },
  methods: {
    addGroup() {
      this.items.push({});
    },
    deleteGroup(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>